<template>
  <div>
    <Header></Header>
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./components/header.vue";
import Footer from "./components/footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      currentPathName: this.$route.name,
    };
  },
  methods: {},
};
</script>

<style scoped lang="scss">

</style>
