import Vue from 'vue';
import VueRouter from 'vue-router';
/* 路由数据 */
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  routes,
});

export default router;
