import layout from '../layout/index.vue';

/* 路由映射数据 */
import routerMap from './routerMap';

const routes = [
  {
    path: '/',
    component: layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: routerMap.home,
      },
    ],
  },
  {
    path: '/aboutDebris',
    component: layout,
    redirect: '/aboutDebris',
    children: [
      {
        path: '/aboutDebris',
        name: 'AboutDebris',
        component: routerMap.aboutDebris,
      },
    ],
  },
  {
    path: '/aboutUs',
    component: layout,
    redirect: '/aboutUs',
    children: [
      {
        path: '/aboutUs',
        name: 'AboutUs',
        component: routerMap.aboutUs,
      },
    ],
  },
  {
    path: '/works',
    component: layout,
    redirect: '/works',
    children: [
      {
        path: '/works',
        name: 'Works',
        component: routerMap.works,
      },
    ],
  },
  {
    path: '/pigSale',
    component: layout,
    redirect: '/pigSale',
    children: [
      {
        path: '/pigSale',
        name: 'PigSale',
        component: routerMap.pigSale,
      },
    ],
  },
  {
    path: '/xiurongTown',
    component: layout,
    redirect: '/xiurongTown',
    children: [
      {
        path: '/xiurongTown',
        name: 'XiurongTown',
        component: routerMap.xiurongTown,
      },
    ],
  },
  {
    path: '/debris',
    component: layout,
    redirect: '/debris',
    children: [
      {
        path: '/debris',
        name: 'Debris',
        component: routerMap.debris,
      },
    ],
  }
];

export default [
  ...routes,
];
