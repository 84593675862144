import Vue from 'vue'
import App from './App.vue'
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/fonts/font.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import '@/assets/icons'
import store from './store';

Vue.use(ElementUI);
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false

// rem计算
function setRemPc() {
  const whdef = 100 / 1920;// 表示1920的设计图,使用100PX的默认值
  const bodyWidth = document.body.clientWidth;// 当前窗口的宽度
  const rem = bodyWidth * whdef;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
  document.getElementsByTagName('html')[0].style.fontSize = `${rem}px`;
}

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    // 实例创建完成后被立即调用rem换算方法,解决内容闪现问题
    setRemPc();
    window.addEventListener('resize', setRemPc);// 浏览器窗口大小改变时调用rem换算方法
  },
}).$mount('#app')
