/* eslint-disable no-shadow */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
const state = {
  debrisLanguageType: 1, // 1代表中文，2代表英文
};
// 获取属性的状态
const getters = {};
const mutations = {
  setLanguageType: (state, data) => {
    state.debrisLanguageType = data;
  }
};
const actions = {
  languageTypeActions: ({ commit }, data) => {
    commit('setLanguageType', data);
  }
};
// 模块
const modules = {
};
export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules,
});
