/* 路由映射 */
export default {
  home: () => import('@/views/home.vue'),
  aboutDebris: () => import('@/views/aboutDebris.vue'),
  aboutUs: () => import('@/views/aboutUs.vue'),
  works: () => import('@/views/works.vue'),
  pigSale: () => import('@/views/pigSale.vue'),
  xiurongTown: () => import('@/views/xiurongTown.vue'),
  debris: () => import('@/views/debris.vue'),
};
